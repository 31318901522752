<template>
  <div>
    <Spinner v-if="fetching" />
    <div class="footer-margin" v-else>
      <section>
        <div class="container-fluid plr-80">
          <div class="property-name-wrapper row">
            <div class="col-md-6 hotel-rating">
              <p>{{ property.name }}</p>
              <h4 class="d-flex align-items-center">
                <span> {{ property.name }}</span>
                <StarRating :value="ratingValue" />
              </h4>
              <div class="access d-flex">
                <li v-if="propertyInfo.addresses">
                  <a
                    :href="`https://www.google.com/maps?q=${propertyInfo.geoCode?.latitude},${propertyInfo.geoCode?.longitude}`"
                    target="_blank"
                    >{{ propertyInfo.addresses[0]?.addressLine }}</a
                  >
                </li>
                <li>
                  <a
                    :href="`https://www.google.com/maps?q=${propertyInfo.geoCode?.latitude},${propertyInfo.geoCode?.longitude}`"
                    target="_blank"
                    >{{ $t("listing.showOnMap") }}</a
                  >
                </li>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="container-fluid plr-80">
          <div class="property-pics-outer row position-relative">
            <div class="col-6 single-image">
              <div class="pics-img-outer" v-if="mainMedia.length">
                <img :src="mainMedia[0].url" alt="" />
              </div>
              <div class="pics-img-outer" v-else>
                <img src="/images/property-default-image.jpg" alt="" />
              </div>
            </div>
            <div class="col-6">
              <div class="row images-4">
                <div
                  v-for="item in mainMedia.splice(1)"
                  :key="item.id"
                  class="col-6"
                >
                  <div class="pics-img-outer">
                    <img :src="item.url" alt="" />
                  </div>
                </div>
                <div
                  v-for="i in Math.max(0, 3 - mainMedia.length)"
                  :key="i"
                  class="col-6"
                >
                  <div class="pics-img-outer">
                    <img src="/images/property-default-image.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BookingCheckout
          v-if="guests && quantity"
          @proceed="$router.push(`/listing/${property.id}/reservation`)"
        />
      </section>

      <section>
        <div class="container-fluid plr-80">
          <div class="property-pics-mob">
            <b-carousel
              id="carousel-fade"
              style="text-shadow: 0px 0px 2px #000"
              fade
              indicators
              img-width="1024"
              img-height="480"
            >
              <template v-if="mainMedia.length">
                <b-carousel-slide
                  v-for="item of mainMedia"
                  :key="item.id"
                  :img-src="item.url"
                ></b-carousel-slide>
              </template>
              <b-carousel-slide
                v-else
                img-src="/images/property-default-image.jpg"
              ></b-carousel-slide>
            </b-carousel>
          </div>
        </div>
      </section>

      <section>
        <div class="container-fluid plr-80">
          <div class="property-desc">
            <div class="row align-items-center">
              <div class="col-md-8">
                <div v-html="description"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section v-if="property.distributorType === 'A'" class="book-stay-wrapper">
        <div class="container px-0">
          <BookingEngineDateSelector
            :checkIn="date.checkIn"
            :checkOut="date.checkOut"
          />
          <div class="my-5">
            <div class="products-list">
              <div
                v-for="i in 5"
                :key="i"
                class="row mx-0 products-list-item py-4 px-2 my-3"
              >
                <div class="col-md-5">
                  <div class="position-relative">
                    <img
                      src="../assets/images/Frame 24.png"
                      class="img-fluid"
                      alt=""
                    />
                    <span class="total-images px-3 py-1 small bg-white"
                      >7 Photos</span
                    >
                  </div>
                </div>
                <div class="col-md-7">
                  <h4 class="mb-4">Apartment 1</h4>
                  <p>
                    This apartment is divided into two levels with a king-size
                    bed in the loft, which is directly above its own living room
                    and bathroom. These two areas can be separated from the rest
                    of the house by the double door leading to the second
                    kitchen / bedroom. A separate door leads to the terrace
                    outside.
                  </p>
                </div>
                <div class="selectors mt-3 pt-3 mx-2">
                  <div class="row">
                    <div class="col-md-5">
                      <div class="small text-danger">
                        <img
                          src="../assets/icons/meal.svg"
                          class="mr-1"
                          alt="meal"
                        />
                        No meal Included
                      </div>
                      <div class="mt-2">Apartment 1 - non refundable rate</div>
                      <div class="small text-main">
                        Rate details | Cancellation policy
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div>Persons</div>
                      <div class="d-flex align-items-center mt-3">
                        <Multiselect
                          placeholder=""
                          style="width: 100px"
                          class="mr-3"
                          :options="personOptions"
                          :showLabels="false"
                          :searchable="false"
                          v-model="guests"
                        >
                          <template slot="singleLabel" slot-scope="props">
                            <img
                              v-for="i in props.option.value"
                              :key="i"
                              src="../assets/icons/person.svg"
                              class="mr-1"
                              alt="person"
                            />
                          </template>
                          <template slot="option" slot-scope="props">
                            <img
                              v-for="i in props.option.value"
                              :key="i"
                              src="../assets/icons/person.svg"
                              class="mr-1"
                              alt="person"
                            />
                          </template>
                        </Multiselect>
                        <div>Adults</div>
                      </div>
                      <div class="mt-1 small">max 3 Persons</div>
                    </div>
                    <div class="col-md-4">
                      <div
                        class="d-flex align-items-center justify-content-between"
                      >
                        <div>
                          <div>Price Per Room/Night</div>
                          <h3 class="text-main font-weight-bold mt-3">
                            EUR 90.00
                          </h3>
                        </div>
                        <div>
                          <div>Room(s)</div>
                          <Multiselect
                            placeholder=""
                            style="width: 70px"
                            class="mt-3"
                            :options="roomOptions"
                            :showLabels="false"
                            :searchable="false"
                            v-model="quantity"
                          >
                          </Multiselect>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        v-if="property.distributorType === 'C'"
        class="container booking-request px-0 my-5"
      >
        <h2 class="text-center mb-3">{{ $t("listing.bookingRequest") }}</h2>
        <div class="card my-4">
          <div class="card-body px-md-5 shadow-sm">
            <h5 class="mb-4">{{ $t("listing.bookingDetails") }}</h5>
            <div class="row">
              <div class="col-md-5 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  readonly
                  :value="bookingOnRequestForm.check_in"
                  @click="showCheckIn = true"
                  :placeholder="`${$t('listing.checkOutDate')}*`"
                />
                <date-picker
                  :open.sync="showCheckIn"
                  v-model="dateRange"
                  value-type="format"
                  format="YYYY-MM-DD"
                  type="date"
                  range
                  placeholder="Select date range"
                ></date-picker>
              </div>
              <div class="col-md-5 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  readonly
                  :value="bookingOnRequestForm.check_out"
                  @click="showCheckOut = true"
                  :placeholder="`${$t('listing.checkInDate')}*`"
                />
                <date-picker
                  :open.sync="showCheckOut"
                  v-model="dateRange"
                  value-type="format"
                  format="YYYY-MM-DD"
                  type="date"
                  range
                  placeholder="Select date range"
                ></date-picker>
              </div>
              <div class="col-md-10 my-3">
                <select
                  class="form-control no-border light-bg"
                  v-model="bookingOnRequestForm.guests"
                >
                  <option value="">{{ $t("listing.noOfGuest") }}</option>
                  <option v-for="i in 10" :key="i" :value="i">{{ i }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="card my-4">
          <div class="card-body px-md-5 shadow-sm">
            <h5 class="mb-4">{{ $t("listing.guestDetails") }}</h5>
            <div class="row">
              <div class="col-md-5 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  v-model="bookingOnRequestForm.guest_first_name"
                  :placeholder="`${$t('listing.firstName')}*`"
                />
              </div>
              <div class="col-md-5 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  v-model="bookingOnRequestForm.guest_last_name"
                  :placeholder="`${$t('listing.lastName')}*`"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="card my-4">
          <div class="card-body px-md-5 shadow-sm">
            <h5 class="mb-4">{{ $t("listing.yourDetails") }}</h5>
            <div class="row">
              <div class="col-md-5 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  v-model="bookingOnRequestForm.first_name"
                  :placeholder="`${$t('listing.firstName')}*`"
                />
              </div>
              <div class="col-md-5 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  v-model="bookingOnRequestForm.last_name"
                  :placeholder="`${$t('listing.lastName')}*`"
                />
              </div>
              <div class="col-md-5 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  v-model="bookingOnRequestForm.email"
                  :placeholder="`${$t('listing.email')}*`"
                />
              </div>
              <div class="col-md-5 my-3 no-border">
                <vue-tel-input
                  v-model="bookingOnRequestForm.phone"
                ></vue-tel-input>
              </div>
              <div class="col-md-4 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  v-model="bookingOnRequestForm.post_code"
                  :placeholder="`${$t('listing.postCode')}*`"
                />
              </div>
              <div class="col-md-4 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  v-model="bookingOnRequestForm.city"
                  :placeholder="`${$t('listing.city')}*`"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="card my-4">
          <div class="card-body px-md-5 shadow-sm">
            <div class="d-flex justify-content-between">
              <h5 class="mb-4">{{ $t("listing.companyDetails") }}</h5>
              <div>
                <button
                  @click="showCompanyDetails = !showCompanyDetails"
                  class="btn btn-outline-main px-5"
                >
                  {{ $t("listing.open") }}
                  <img
                    src="../assets/icons/open.svg"
                    class="mr-1"
                    :alt="$t('listing.open')"
                  />
                </button>
              </div>
            </div>
            <div class="row" v-if="showCompanyDetails">
              <div class="col-md-10 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  v-model="bookingOnRequestForm.company_name"
                  :placeholder="`${$t('listing.companyName')}`"
                />
              </div>
              <div class="col-md-10 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  v-model="bookingOnRequestForm.company_address_1"
                  :placeholder="`${$t('listing.address1')}`"
                />
              </div>
              <div class="col-md-10 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  v-model="bookingOnRequestForm.company_address_2"
                  :placeholder="`${$t('listing.address2')}`"
                />
              </div>
              <div class="col-md-3 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  v-model="bookingOnRequestForm.company_city"
                  :placeholder="`${$t('listing.city')}`"
                />
              </div>
              <div class="col-md-3 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  v-model="bookingOnRequestForm.company_post_code"
                  :placeholder="`${$t('listing.postCode')}`"
                />
              </div>
              <div class="col-md-3 my-3">
                <select
                  class="form-control no-border"
                  v-model="bookingOnRequestForm.company_country"
                  :placeholder="`${$t('listing.country')}`"
                >
                  <option value="">{{ $t("listing.country") }}</option>
                  <option
                    v-for="country in countries"
                    :key="country"
                    :value="country"
                  >
                    {{ country }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="card my-4">
          <div class="card-body px-md-5 shadow-sm">
            <div class="d-flex justify-content-between">
              <h5 class="mb-4">{{ $t("listing.specialRequest") }}</h5>
              <div>
                <button
                  @click="showSpecialRequest = !showSpecialRequest"
                  class="btn btn-outline-main px-5"
                >
                  {{ $t("listing.open") }}
                  <img
                    src="../assets/icons/open.svg"
                    class="mr-1"
                    :alt="$t('listing.open')"
                  />
                </button>
              </div>
            </div>
            <div class="row" v-if="showSpecialRequest">
              <div class="col-md-10 my-3">
                <textarea
                  class="form-control no-border"
                  v-model="bookingOnRequestForm.special_request"
                  :placeholder="$t('listing.specialRequestOrWish')"
                  rows="7"
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="text-right border-left pl-4">
          <button
            :disabled="!bookingOnRequestFormValid"
            @click="submitBookingOnRequestForm"
            class="btn btn-main px-5 py-2"
          >
            {{ $t("listing.sendRequest") }}
          </button>
        </div>
      </div>

      <section class="facilities" v-if="false">
        <div class="container-fluid plr-80">
          <div class="row">
            <div class="col-12">
              <h2 class="heading border-bottom">Facilities and extras</h2>
            </div>
          </div>

          <div class="row facility-listing">
            <div class="col-md-3 col-lg-3">
              <h5>
                <span><img src="../assets/images/bath.svg" alt="" /></span>
                Bathroom
              </h5>

              <ul>
                <li>
                  <p>Lock on bedroom door</p>
                </li>
                <li>
                  <p>Hangers</p>
                </li>
                <li>
                  <p>Hair dryer</p>
                </li>
                <li>
                  <p>Iron</p>
                </li>
                <li>
                  <p>Washer</p>
                </li>
                <li>
                  <p>Drye</p>
                </li>
              </ul>
              <h5>
                <span><img src="../assets/images/plug.svg" alt="" /></span>
                Media & tehcnology
              </h5>

              <ul>
                <li>
                  <p>Lock on bedroom door</p>
                </li>
                <li>
                  <p>Hangers</p>
                </li>
                <li>
                  <p>Hair dryer</p>
                </li>
                <li>
                  <p>Iron</p>
                </li>
                <li>
                  <p>Washer</p>
                </li>
                <li>
                  <p>Drye</p>
                </li>
              </ul>
            </div>
            <div class="col-md-3 col-lg-3">
              <h5>
                <span><img src="../assets/images/bath.svg" alt="" /></span>
                Kitchen
              </h5>

              <ul>
                <li>
                  <p>Lock on bedroom door</p>
                </li>
                <li>
                  <p>Hangers</p>
                </li>
                <li>
                  <p>Hair dryer</p>
                </li>
                <li>
                  <p>Iron</p>
                </li>
                <li>
                  <p>Washer</p>
                </li>
                <li>
                  <p>Drye</p>
                </li>
                <li>
                  <p>Lock on bedroom door</p>
                </li>
                <li>
                  <p>Hangers</p>
                </li>
                <li>
                  <p>Hair dryer</p>
                </li>
                <li>
                  <p>Iron</p>
                </li>
                <li>
                  <p>Washer</p>
                </li>
                <li>
                  <p>Drye</p>
                </li>
              </ul>
            </div>
            <div class="col-md-3 col-lg-3">
              <h5>
                <span><img src="../assets/images/car-icon.svg" alt="" /></span>
                Bedroom
              </h5>

              <ul>
                <li>
                  <p>Lock on bedroom door</p>
                </li>
                <li>
                  <p>Hangers</p>
                </li>
                <li>
                  <p>Hair dryer</p>
                </li>
                <li>
                  <p>Iron</p>
                </li>
                <li>
                  <p>Washer</p>
                </li>
                <li>
                  <p>Drye</p>
                </li>
              </ul>
              <h5>
                <span><img src="../assets/images/wifi.svg" alt="" /></span>
                Internet
              </h5>

              <ul>
                <li>
                  <p>Lock on bedroom door</p>
                </li>
                <li>
                  <p>Hangers</p>
                </li>
                <li>
                  <p>Hair dryer</p>
                </li>
                <li>
                  <p>Iron</p>
                </li>
                <li>
                  <p>Washer</p>
                </li>
                <li>
                  <p>Drye</p>
                </li>
              </ul>
            </div>
            <div class="col-md-3 col-lg-3">
              <h5>
                <span><img src="../assets/images/balcony.svg" alt="" /></span>
                Outdoors
              </h5>

              <ul>
                <li>
                  <p>Lock on bedroom door</p>
                </li>
                <li>
                  <p>Hangers</p>
                </li>
                <li>
                  <p>Hair dryer</p>
                </li>
                <li>
                  <p>Iron</p>
                </li>
                <li>
                  <p>Washer</p>
                </li>
                <li>
                  <p>Drye</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section class="guest-review">
        <div class="container-fluid plr-80">
          <div class="row" v-if="false">
            <div class="col-12">
              <h2 class="heading">Guest reviews</h2>
            </div>

            <div class="col-12 read-review border-bottom">
              <div class="review d-flex">
                <span class="rating">8.6</span>
                <h5 class="text-center">
                  Very good
                  <span class="d-block text-center">2,176 reviews</span>
                </h5>
              </div>
              <a href="#" class="orange-button">Read all reviews</a>
            </div>
          </div>

          <div class="row rating-bar-wrapper border-bottom" v-if="false">
            <div class="col-md-5 col-lg-5">
              <div class="rating-bar-inner">
                <h4>Cleanliness</h4>
                <div class="rating-bar">
                  <b-progress :value="value"></b-progress><span>8.0</span>
                </div>
              </div>
              <div class="rating-bar-inner">
                <h4>Communication</h4>
                <div class="rating-bar">
                  <b-progress :value="value"></b-progress><span>8.0</span>
                </div>
              </div>
              <div class="rating-bar-inner">
                <h4>Check-in</h4>
                <div class="rating-bar">
                  <b-progress :value="value"></b-progress><span>8.0</span>
                </div>
              </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-5 col-lg-5">
              <div class="rating-bar-inner">
                <h4>Accuracy</h4>
                <div class="rating-bar">
                  <b-progress :value="value"></b-progress><span>8.0</span>
                </div>
              </div>
              <div class="rating-bar-inner">
                <h4>Location</h4>
                <div class="rating-bar">
                  <b-progress :value="value"></b-progress><span>8.0</span>
                </div>
              </div>
              <div class="rating-bar-inner">
                <h4>Value</h4>
                <div class="rating-bar">
                  <b-progress :value="value"></b-progress><span>8.0</span>
                </div>
              </div>
            </div>
          </div>

          <div class="row guest-rating" v-if="false">
            <div class="col-md-5 col-lg-5">
              <div class="guest-rating-inner">
                <div class="avtaar-cnt d-flex">
                  <b-avatar src="../assets/images/user-image.jpg"></b-avatar>
                  <h5>John Doe<span>July 2021</span></h5>
                </div>
                <p>
                  “ Very nice place, very kind hospitality, every morning
                  different homemade breakfast, fresh fruit salad, so it was
                  fantastic ”
                </p>
              </div>
              <div class="guest-rating-inner">
                <div class="avtaar-cnt d-flex">
                  <b-avatar src="../assets/images/user-image.jpg"></b-avatar>
                  <h5>John Doe<span>July 2021</span></h5>
                </div>
                <p>
                  “ Very nice place, very kind hospitality, every morning
                  different homemade breakfast, fresh fruit salad, so it was
                  fantastic ”
                </p>
              </div>
              <div class="guest-rating-inner">
                <div class="avtaar-cnt d-flex">
                  <b-avatar src="../assets/images/user-image.jpg"></b-avatar>
                  <h5>John Doe<span>July 2021</span></h5>
                </div>
                <p>
                  “ Very nice place, very kind hospitality, every morning
                  different homemade breakfast, fresh fruit salad, so it was
                  fantastic ”
                </p>
              </div>
            </div>
            <div class="col-md-1 col-lg-1"></div>
            <div class="col-md-5 col-lg-5">
              <div class="guest-rating-inner">
                <div class="avtaar-cnt d-flex">
                  <b-avatar src="../assets/images/user-image.jpg"></b-avatar>
                  <h5>John Doe<span>July 2021</span></h5>
                </div>
                <p>
                  “ Very nice place, very kind hospitality, every morning
                  different homemade breakfast, fresh fruit salad, so it was
                  fantastic ”
                </p>
              </div>
              <div class="guest-rating-inner">
                <div class="avtaar-cnt d-flex">
                  <b-avatar src="../assets/images/user-image.jpg"></b-avatar>
                  <h5>John Doe<span>July 2021</span></h5>
                </div>
                <p>
                  “ Very nice place, very kind hospitality, every morning
                  different homemade breakfast, fresh fruit salad, so it was
                  fantastic ”
                </p>
              </div>
              <div class="guest-rating-inner">
                <div class="avtaar-cnt d-flex">
                  <b-avatar src="../assets/images/user-image.jpg"></b-avatar>
                  <h5>John Doe<span>July 2021</span></h5>
                </div>
                <p>
                  “ Very nice place, very kind hospitality, every morning
                  different homemade breakfast, fresh fruit salad, so it was
                  fantastic ”
                </p>
              </div>
            </div>
          </div>

          <div class="contactUs row">
            <div class="col-12">
              <iframe
                :src="`https://www.google.com/maps?q=${propertyInfo.geoCode?.latitude},${propertyInfo.geoCode?.longitude}&hl=es;z=14&output=embed`"
                width="100%"
                height="538"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </div>
            <div class="contactUs-txt d-flex" v-if="false">
              <div class="col-md-5 col-lg-5">
                <h2 class="heading">Berlin, Germany</h2>
                <p class="">
                  The house is located in Moabit, part of the famous
                  Berlin-Mitte district. As the meeting point between the
                  historic east and west, there's a multitude of cultural,
                  nightlife and sightseeing...
                </p>
                <a href="#" class="anchor-txt">More about the location</a>
              </div>
              <div class="col-1"></div>
              <div class="col-md-5 col-lg-5">
                <h2 class="heading">Distance from Berlin Tegel Airport</h2>
                <p>15 mins by car without traffic</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer ref="footerRef"></Footer>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Spinner from "./Widgets/Spinner.vue";
import StarRating from "./Widgets/StarRating.vue";
import BookingCheckout from "./Widgets/BookingCheckout.vue";
import BookingEngineDateSelector from "./Widgets/BookingEngineDateSelector.vue";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "Property",
  components: {
    Spinner,
    StarRating,
    DatePicker,
    Multiselect,
    BookingCheckout,
    BookingEngineDateSelector,
  },

  data() {
    return {
      dateRange: null,
      showCheckIn: false,
      showCheckOut: false,
      showBookNow: true,
      selected: "null",
      personOptions: [0, 1, 2, 3, 4, 5],
      roomOptions: [0, 1, 2, 3, 4, 5],
      guests: 3,
      quantity: 0,
      options: [
        {
          text: "0",
          value: null,
        },
        {
          text: "1",
          value: 1,
        },
        {
          text: "2 ",
          value: 2,
        },
        {
          text: "3 ",
          value: 3,
        },
        {
          text: "4 ",
          value: 4,
        },
      ],
      value: "",
      date: {
        checkIn: null,
        checkOut: null,
      },
      showSpecialRequest: false,
      showCompanyDetails: false,
      bookingOnRequestForm: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        post_code: "",
        city: "",
        guest_first_name: "",
        guest_last_name: "",
        check_in: "",
        check_out: "",
        guests: "",
        company_name: "",
        company_address_1: "",
        company_address_2: "",
        company_city: "",
        company_post_code: "",
        company_country: "",
        special_request: "",
      },
      countries: [
        "Afghanistan",
        "Albania",
        "Algeria",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antigua &amp; Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia",
        "Bosnia &amp; Herzegovina",
        "Botswana",
        "Brazil",
        "British Virgin Islands",
        "Brunei",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cambodia",
        "Cameroon",
        "Cape Verde",
        "Cayman Islands",
        "Chad",
        "Chile",
        "China",
        "Colombia",
        "Congo",
        "Cook Islands",
        "Costa Rica",
        "Cote D Ivoire",
        "Croatia",
        "Cruise Ship",
        "Cuba",
        "Cyprus",
        "Czech Republic",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Estonia",
        "Ethiopia",
        "Falkland Islands",
        "Faroe Islands",
        "Fiji",
        "Finland",
        "France",
        "French Polynesia",
        "French West Indies",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea Bissau",
        "Guyana",
        "Haiti",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Isle of Man",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jersey",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kuwait",
        "Kyrgyz Republic",
        "Laos",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macau",
        "Macedonia",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Mauritania",
        "Mauritius",
        "Mexico",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Namibia",
        "Nepal",
        "Netherlands",
        "Netherlands Antilles",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Norway",
        "Oman",
        "Pakistan",
        "Palestine",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Reunion",
        "Romania",
        "Russia",
        "Rwanda",
        "Saint Pierre &amp; Miquelon",
        "Samoa",
        "San Marino",
        "Satellite",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Slovakia",
        "Slovenia",
        "South Africa",
        "South Korea",
        "Spain",
        "Sri Lanka",
        "St Kitts &amp; Nevis",
        "St Lucia",
        "St Vincent",
        "St. Lucia",
        "Sudan",
        "Suriname",
        "Swaziland",
        "Sweden",
        "Switzerland",
        "Syria",
        "Taiwan",
        "Tajikistan",
        "Tanzania",
        "Thailand",
        "Timor L'Este",
        "Togo",
        "Tonga",
        "Trinidad &amp; Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks &amp; Caicos",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "Uruguay",
        "Uzbekistan",
        "Venezuela",
        "Vietnam",
        "Virgin Islands (US)",
        "Yemen",
        "Zambia",
        "Zimbabwe",
      ],
    };
  },
  computed: {
    ...mapState("booking", ["pending"]),
    ...mapState("property", ["propertyData", "fetching"]),

    property() {
      return this.propertyData.property;
    },
    propertyInfo() {
      return this.propertyData.propertyInfo;
    },
    ratingValue() {
      const value = this.propertyData.googleReviews?.googleRating || 0;
      return parseFloat(value > 0 ? (value / 2).toFixed(1) : value);
    },
    description() {
      const description = this.property.descriptions.find(
        (desc) => desc.descriptionType.code === "hotel-long"
      );
      return description?.text;
    },
    mainMedia() {
      let mainMedia = this.propertyData.media
        .filter((m) => {
          return m?.isMain && m.mediaType.code === "img";
        })
        .map((m) => {
          m.order = m.sortOrderMain || Number.MAX_VALUE;
          return m;
        });

      if (!mainMedia.length) {
        mainMedia = this.propertyData.media
          .filter((m) => {
            return m.mediaType.code === "img" && m.sellableUnitIds.length > 0;
          })
          .map((m) => {
            m.order = m.sortOrder || Number.MAX_VALUE;
            return m;
          });
      }

      if (!mainMedia.length) {
        mainMedia = this.propertyData.media
          .filter((m) => {
            return m.mediaType.code === "img";
          })
          .map((m) => {
            m.order = m.sortOrder || Number.MAX_VALUE;
            return m;
          });
      }

      return mainMedia.sort((a, b) => a.order - b.order).slice(0, 4);
    },
    bookingOnRequestFormValid() {
      const {
        first_name,
        last_name,
        email,
        post_code,
        city,
        phone,
        guest_first_name,
        guest_last_name,
        check_in,
        check_out,
        guests,
      } = this.bookingOnRequestForm;
      return (
        first_name &&
        last_name &&
        email &&
        post_code &&
        city &&
        phone &&
        guest_first_name &&
        guest_last_name &&
        check_in &&
        check_out &&
        guests
      );
    },
  },
  methods: {
    ...mapActions("property", ["getPropertyById"]),
    ...mapActions("booking", ["initiateBookingOnRequest"]),
    onScroll() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      var element = this.$refs.footerRef;
      var top = element.offsetTop;

      if (currentScrollPosition >= top) {
        this.showBookNow = false;
      } else {
        this.showBookNow = true;
      }
    },
    async submitBookingOnRequestForm() {
      if (!this.bookingOnRequestFormValid) {
        return;
      }

      const language = this.propertyInfo.languages.find(
        ({ isMain }) => !!isMain
      );

      let email = this.property.emails.find(
        ({ emailType }) => emailType.code === "primary"
      );
      if (email) {
        email = email.email;
      } else {
        email = this.property.emails[0]?.email;
      }

      if (email) {
        await this.initiateBookingOnRequest({
          ...this.bookingOnRequestForm,
          property_name: this.property.name,
          property_email: email,
          property_address: this.propertyInfo.addresses.length
            ? this.propertyInfo.addresses[0]?.addressLine
            : "N/A",
          property_phone: this.property.phones.length
            ? this.property.phones[0]?.phoneNumber
            : "N/A",
          property_language: language ? language.language?.code || "en" : "en",
          booker_language: localStorage.getItem("locale") || "en",
        });

        this.$router.push(`/reservation_success`);
      }
    },
  },
  async created() {
    const { id } = this.$route.params;
    const { checkIn, checkOut } = this.$route.query;
    if (!id) {
      this.$router.push("/listing");
    }
    await this.getPropertyById(id);
    if (checkIn && checkOut) {
      this.date.checkIn = checkIn;
      this.date.checkOut = checkOut;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  watch: {
    dateRange(value) {
      if (value) {
        this.bookingOnRequestForm.check_in = value[0];
        this.bookingOnRequestForm.check_out = value[1];
      } else {
        this.bookingOnRequestForm.check_in = null;
        this.bookingOnRequestForm.check_out = null;
      }
    },
  },
};
</script>
